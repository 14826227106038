@import url(/src/styles/Fonts.css);
@import url(/src/styles/Global.css);

.projectContent {
  margin: auto;
  width: 1200px;
  display: block;
}

h1 {
  font-family: IbmPlexMono;
  color: var(--secondaryText);
  font-size: 14px;
  font-weight: 400;
  display: block;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 24px;
}

.card {
  margin-top: 12px;
  border: 1px solid var(--outlineStroke);
  padding: 16px;
}

.card img {
  width: 100%;
  height: auto;
  display: block;
}

.cardContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.cardContent h2 {
  font-family: GeistSans;
  color: var(--primaryText);
  font-size: 14px;
  font-weight: 400;
  margin: 0; 
}

.cardContent img {
  width: 16px;
  height: auto;
  margin-left: 8px; 
}

@media (max-width: 1200px) {
  .projectContent {
    width: 90%;
  }
}

@media (max-width: 992px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .cards {
    grid-template-columns: 1fr;
  }
}
