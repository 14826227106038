@import url(/src/styles/Fonts.css);
@import url(/src/styles/Global.css);

* {
  background-color: var(--background);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  padding: 20px 20px;
  position: relative;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.topLeftNav ul, .topRightNav ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.topLeftNav, .navItems, .topRightNav {
  display: flex;
  align-items: center;
}

.navItems ul {
  display: flex;
  flex-direction: row; 
  justify-content: center; 
  align-items: center; 
  padding: 0;
}

.navItems li {
  margin-right: 100px;
}

li.primaryText {
  margin-top: 8px; 
}

.topRightNav {
  display: flex;
  align-items: center;
  justify-content: flex-end; 
}

.topRightNav ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  padding: 0;
}

a {
  color: var(--secondaryText);
  text-decoration: none;
}

.secondaryText a:hover {
  color: var(--primaryText);
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 16px;
  cursor: pointer;
}

.hamburger .line {
  width: 24px;
  height: 2px;
  background-color: var(--secondaryText);
}

@media (max-width: 1100px) {
  .hamburger {
    display: flex;
  }

  .navItems {
    position: absolute;
    top: 40px;
    right: 20px;
    background-color: var(--background);
    width: 160px;
    border: 1px solid var(--outlineStroke);
    display: none;
    flex-direction: column;
    align-items: center; 
    padding: 20px;
    padding-bottom: 4px;
  }

  .navItems.open {
    display: flex;
  }

  .navItems ul {
    flex-direction: column;
    align-items: center; 
  }

  .navItems li {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }


  .topLeftNav {
    flex: 1;
  }

  .topRightNav {
    display: none;
  }
}
