@import url(/src/styles/Fonts.css);
@import url(/src/styles/Global.css);

.pageContent {
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 480px;
  display: block;
}

.contentBlock {
  margin-top: 60px;
  display: block;
}

p {
  font-family: GeistSans;
  color: var(--primaryText);
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin-top: 12px;
  font-weight: 400;
}

.inline-a {
  font-family: GeistSans;
  color: var(--primaryText);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

svg {
  margin-left: 2px;
  margin-right: 2px;
}

/* Media Queries for Page Content */
@media (max-width: 768px) {
  .pageContent {
    width: 90%;
  }
}

@media (max-width: 576px) {
  p {
    font-size: 14px;
    line-height: 20px;
  }
}
