@font-face {
    font-family: GeistSans;
    src: url(../../public/fonts/Geist-Medium.ttf);
}

@font-face {
    font-family: IbmPlexMono;
    src: url(../../public/fonts/ibmPlexMono.otf);
}

.primaryText {
    font-family: GeistSans;
    color: var(--primaryText);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  
  .secondaryText {
    font-family: IbmPlexMono;
    color: var(--secondaryText);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
