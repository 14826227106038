/* Dark Mode Theme */

:root {
    --background: #000;
    --primaryText: #EDEDED;
    --secondaryText: #A1A1A1;
    --outlineStroke: #404040;
}

/* Light Mode Theme */

@media (prefers-color-scheme: light) {
    :root {
        --background: #FAFAFA;
        --primaryText: #171717;
        --secondaryText: #666666;
        --outlineStroke: rgba(0, 0, 0, 0.15);
    }
}

html {
    cursor: url('/public/images/figmaCursor.svg'), auto;
}

::selection {
    background: #2937E8;
  }

  
